export default {
  created() {
    this.getInit();
  },
  data() {
    return {
      dialogImageShow: false,
      dialogImageUrl: '',
      size: 'meduim',
      searchData: '',
      tableMaxHeight: '',
      model_simple_cloud_admin_account: {},
      dataList: [],
      page: {
        pageSize: 20,
        startPage: 1,
        totalResult: 0
      },
      accountDialogShow: false,
      currentRow: {},
      editType: '',
      queryModel: {},
      fileList: [],
      fileType: 1,
      currentColumns: [{
        title: '文件',
        isNull: 1,
        type: 'upload'
      }]
    };
  },
  methods: {
    getInit() {
      this.tableMaxHeight = window.innerHeight - 210;

      //初始化
      this.queryModel = {};
      this.getMyAccount();
    },
    previewImg(img) {
      this.dialogImageShow = true;
      this.dialogImageUrl = img;
    },
    sizeChange(val) {
      this.page.startPage = 1;
      this.page.pageSize = val;
      this.getMyAccount();
    },
    currentChange(val) {
      this.page.startPage = val;
      this.getMyAccount();
    },
    async deleteFile(id) {
      let data = {};
      let params = {};
      params.ephemeralParam = id;
      data.params = JSON.stringify(params);
      const res = await this.withLoading(this.apiAll(data, '/deleteFile'));
      if (res.code >= 0) {
        this.getMyAccount();
      } else {
        this.errorMsgBox(res.message);
      }
    },
    async getMyAccount() {
      let data = {};
      let params = {};
      params.page = this.page;
      params.ephemeralParam1 = this.searchData;
      data.params = JSON.stringify(params);
      const res = await this.withLoading(this.apiAll(data, '/getFile'));
      if (res.code >= 0) {
        this.dataList = res.extra;
        this.page = res.page;
      } else {
        this.errorMsgBox(res.message);
      }
    },
    copyThirdRegistUrl(content) {
      if (navigator.clipboard && window.isSecureContext) {
        navigator.clipboard.writeText(content).then(() => {
          this.successMsgBox('复制成功');
        });
      } else {
        copyToClip(content);
      }
    },
    copyToClip(content) {
      var aux = document.createElement('input');
      aux.setAttribute('value', content);
      document.body.appendChild(aux);
      aux.select();
      document.execCommand('copy');
      document.body.removeChild(aux);
      if (message == null) {
        this.successMsgBox('复制成功');
      } else {
        this.successMsgBox(message);
      }
    },
    async submit() {
      if (!this.fileList || this.fileList.length <= 0) {}
      const formData = new FormData();
      if (this.fileList && this.fileList.length > 0) {
        this.fileList.forEach(element => {
          formData.append('fileList', element.raw);
        });
      }
      const res = await this.withLoading(this.formDataApi(formData, '/uploadFile'));
      this.successMsgBox(res.message);
      this.getMyAccount();
      this.accountDialogShow = false;
    },
    async deleteInterview(id) {
      //   let data = {};
      //   let params = {};
      //   params.ephemeralParam = id;
      //   data.params = JSON.stringify(params);
      //   const res = await this.withLoading(this.apiAll(data, "/deleteInterview"));
      //   if (res.code >= 0) {
      //     this.successMsgBox(res.message);
      //     this.getInit();
      //   } else {
      //     this.errorMsgBox(res.message);
      //   }
    },
    handleRemove(f, fileList) {
      this.fileList.some((element, index) => {
        if (element.name == f.name) {
          this.fileList.splice(index, 1);
        }
      });
    },
    openEdit(row, editType) {
      this.accountDialogShow = true;
    }
  }
};